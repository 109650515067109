// Modules
import { useState, useEffect } from 'react';
import i18next from 'i18next';

// Styles
import './clock.styles.scss';

/**
 * Clock Component
 * @description This component shows the current time and date
 * @returns {JSX.Element}
 */
export function ClockComponent() {

    // current date
    const [date, setDate] = useState(new Date());
    const [language, setLanguage] = useState(localStorage.getItem('language') ?? navigator.language);

    // refresh clock
    function refreshClock() {
        setDate(new Date());
    }

    useEffect(() => {

        // refresh clock every second
        const timerId = setInterval(refreshClock, 1000);

        const handleLanguageChange = (lng: string) => {
          setLanguage(lng);
        };

        i18next.on('languageChanged', handleLanguageChange);

        // clear interval
        return function cleanup() {
            i18next.off('languageChanged', handleLanguageChange);
            clearInterval(timerId);
        };
    }, []);

    return <>
        <div
            className='ClockBackgroundComponent'
            style={{
                backgroundImage: 'url(/images/home.jpg)',
            }}
        />

        <div className='ClockComponent'>
            <div className="hour">
                {
                    date.toLocaleTimeString(navigator.language, {
                        hour: 'numeric',
                        minute: '2-digit',
                    })
                }
            </div>
            <div className="day">
                {
                    date.toLocaleDateString(language, {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                    })
                }
            </div>
        </div>
    </>

}
