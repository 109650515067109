import React, { useEffect } from 'react';
import { HelpProperties } from './help.properties';

import './help.styles.scss';
import axios from 'axios';

import { environment } from '../../environment';
import { useTranslation } from 'react-i18next';

export function HelpComponent(props: HelpProperties) {
  const [activeSection, setActiveSection] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchResults, setSearchResults] = React.useState<{id: string, name: string}[]>([]);
  const [noResults, setNoResults] = React.useState<boolean>(false);
  const [sectionsIndex, setSectionsIndex] = React.useState<{}[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [displayResults, setDisplayResults] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const toggleAccordion = (section: string) => {
    setActiveSection(activeSection === section ? '' : section);
    setSearchTerm('');
  };

  const [manualInfo, setManualInfo] = React.useState<{id: string, name: string, content: string}>({
    id: '',
    name: '',
    content: ''
  });

  const highlightSearchTerm = (text: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return text.replace(regex, '<strong>$1</strong>');
  };

  const search = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (!term) {
      setSearchResults([]);
      setNoResults(false);
      setDisplayResults(false);
      return;
    }

    setLoading(true);

    setTimeout(() => {
      axios.get(`${environment.api.host}/api/manuals/search/${term}`)
        .then(response => {
          if (response.data.length === 0) {
            setNoResults(true);
            setSearchResults([]);
          } else {
            setNoResults(false);
            setSearchResults(response.data);
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 1000);
  };

  useEffect(() => {
    setLoading(true);

    if (!props.lastUsedHelp) {
      axios.get(`${environment.api.host}/api/manuals/all-manuals`)
      .then(response => {
        setSectionsIndex(response.data);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
    } else {
      axios.get(`${environment.api.host}/api/manuals/${props.lastUsedHelp}`)
      .then(response => {
        setManualInfo(response.data);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, [props.lastUsedHelp]);

  const renderSections = (sections: any) => {
    return (
      <div className='Sections'>
        {sections.map((section: any) => {
          return <div key={section.id} className={`Main-Section ${activeSection === section.id ? 'active' : ''}`}>
            <div className='Accordion-Header' onClick={() => toggleAccordion(section.id)}>
              <span>{section.name}</span>
              <i className={`las la-angle-${activeSection === section.id ? 'up' : 'down'}`}></i>
            </div>

            <div className='Accordion-Content'>
              {section.manuals.length > 0 && (
                <ul className='Manual-List'>
                  {section.manuals.map((manual: any) => (
                    <li key={manual.id} onClick={() => props.setLastUsedHelp(manual.id)}>{manual.name}</li>
                  ))}
                </ul>
              )}
              {section.subsections.length > 0 && (
                <div className='Subsections'>
                  {section.subsections.map((subsection: any) => (
                    <div key={subsection.id} className='Subsection'>
                      <div className='Subsection-Header'>
                        <span>{subsection.name}</span>
                      </div>
                      <div className='Subsection-Content'>
                        {subsection.manuals.length > 0 && (
                          <ul className='Manual-List'>
                            {subsection.manuals.map((manual: any) => (
                              <li key={manual.id} onClick={() => props.setLastUsedHelp(manual.id)}>{manual.name}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        })}
      </div>
    );
  };

  return <div className="HelpComponent Modal Right">
    <div className="Header">
      <span>{`${t("help.title")}`}</span>
      <i className="las la-times" onClick={() => { props.setScene(undefined) }}></i>
    </div>
    <div className="Body">
      <div className="Input">
        <i className="las la-search"></i>
        <input type="text" placeholder={`${t("help.search")}`}
          onChange={search}
          onFocus={() => setDisplayResults(true)}
          onBlur={() => setDisplayResults(false)}
        />
        {
          searchResults.length > 0 && displayResults && <ul className="search-results">
            {searchResults.map(result => (
              <li key={result.id}
                dangerouslySetInnerHTML={{ __html: highlightSearchTerm(result.name) }}
                onMouseDown={() => {
                  props.setLastUsedHelp(result.id);
                }}
                onClick={() => {
                  setDisplayResults(false);
                }}
              />
            ))}
          </ul>
        }
        {
          noResults && <ul className="no-results">
            <li>{`${t('help.no-results', {searchTerm: searchTerm})}`}</li>
          </ul>
        }
      </div>
      <div className="Content">
        {
          props.lastUsedHelp && <div className='Manual-Info'>
            <span className="BackToIndex" onClick={() => props.setLastUsedHelp('')}><i className="las la-arrow-left"></i>{`${t('help.back')}`}</span>
            <h2>{manualInfo.name}</h2>
            <div className="Manual-Content" dangerouslySetInnerHTML={{ __html: manualInfo.content }}></div>
          </div>
        }

        {
          !props.lastUsedHelp && sectionsIndex && renderSections(sectionsIndex)
        }

      </div>
    </div>
  </div>
}
