import { useEffect, useState } from "react";
import i18next from 'i18next';

import './time.styles.scss';

export function TimeComponent() {
  const [dateTime, setDateTime] = useState(new Date());
  const [language, setLanguage] = useState(i18next.language);

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    const handleLanguageChange = (lng: string) => {
      setLanguage(lng);
    };

    i18next.on('languageChanged', handleLanguageChange);

    return () => {
      clearInterval(interval);
      i18next.off('languageChanged', handleLanguageChange);
    };
  }, []);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString(language, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
    });
  };

  const formatTime = (date: Date) => {
    return date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return <>
    <div className="TimeComponent">
      <div className='userInfo-date-hour'>
        <div className='userInfo-hour'>
          {formatTime(dateTime)}
        </div>
        <div className='userInfo-date'>
          {formatDate(dateTime)}
        </div>
      </div>
    </div>
  </>
}
