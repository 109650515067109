import axios from "axios"
import { useEffect, useState } from "react";
import { environment } from "../../../../environment";
import i18next from 'i18next';

// Import the styles
import './languageSelector.styles.scss';

interface Language {
  id: string;
  code: string;
  flag_icon: string;
}

export function LanguageSelectorComponent(properties: {
  setScene: (scene: string | undefined) => void;
  room?: string;
}): JSX.Element {
  const [languages, setLanguages] = useState<Language[]>([]);

  const updateLocaleUser = (event: any) => {
    const langId = event.currentTarget.id;
    const user = localStorage.getItem('user');
    const userId = user ? JSON.parse(user).id : null;
    const languageCode = languages.find((language) => language.id === langId)?.code;

    if (languageCode && userId) {
      i18next.changeLanguage(languageCode);
      localStorage.setItem('language', languageCode);

      // Update the user's language in the backend
      axios.put(`${environment.api.host}/api/user/locale/${userId}`, {
        preferred_locale: langId
      })
        .then((response) => {
          console.log('User language updated', response.data);

          // Update the user's language in the local storage
          const user = localStorage.getItem('user');
          if (user) {
            const userObject = JSON.parse(user);
            userObject.user_preferred_locale = { id: langId, code: languageCode };
            localStorage.setItem('user', JSON.stringify(userObject));
          }
        })
        .catch((error) => {
          console.error('Error updating user language', error);
        });
    }

    // Close the language selector
    properties.setScene(undefined);
  }

  useEffect(() => {
    axios.get(`${environment.api.host}/api/locales`)
      .then((response) => {
        setLanguages(response.data);

        console.log('Languages', response.data);
      })
      .catch((error) => {
        console.error('Error retrieving languages', error);
      });
  }, []);

  return <div className={`LanguageSelectorComponent ${properties.room ? 'on-room' : ''}`}>
    {
      languages.map((language) => {
        return (
          <div  key={language.id}
                className="language-container"
                onClick={updateLocaleUser}
                id={language.id}
          >
            <img src={language.flag_icon} alt={language.code} className="flag-img"/>
            <p className="language-code">{language.code}</p>
          </div>
        )
      })
    }
  </div>
}
